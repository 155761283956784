import React, { useEffect, useState, useContext } from "react";
import { AuthContext, baseUrl } from "./../../App";
import { useHistory } from "react-router-dom";
import axios from "axios";
// import { format } from "timeago.js";
import "./post.css";
import { useForm } from "react-hook-form";
import UpdateProfilePhoto from "./../../pages/profile/UpdateProfilePhoto";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Chat, Cancel } from "@material-ui/icons";
import MenuDots from "../menuBurger/MenuDots";
import { useAuthState } from "../../context/auth";

// import Comment from './../comment/Comment';

// const USER_INFO_URL = `${baseUrl}/api/users`;

// const `${baseUrl}/images` = `${baseUrl}/images`;
export default function Post({ post }) {
  const { handleSubmit, register } = useForm();
  const [data, setData] = useState("");
  const [showUpdatePhoto, setShowUpdatePhoto] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [comments, setComments] = useState([]);

  const [newComment, setNewComment] = useState("");
  const [users, setUsers] = useState([]);
  // eslint-disable-next-line
  // const { user } = useAuthState();
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [isLoaded, setIsLoaded] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  //   const postId = post.id;
  const token = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [like, setLike] = useState([]);
  useEffect(() => {
    const fetchUser = async () => {
      const res = await axios.get(`${baseUrl}/api/users`, {
        headers,
      });
      setUsers(res.data);
      localStorage.setItem("userAount", JSON.stringify(res.data));
      console.log(res.data);
    };
    fetchUser();
  }, [token]);
  const likeHandler = () => {
    axios
      .post(
        `${baseUrl}/api/likes`,
        {
          postId: post.id,
          userId: user.id,
          like: 1,
        },
        {
          headers,
        }
      )
      .then((response) => {
        setLike(response.data.like);
        localStorage.setItem("like", JSON.stringify(response.data));
        console.log(like);
      });
  };
  useEffect(() => {
    axios
      .get(`${baseUrl}/api/posts/byId/${post.id}`, {
        headers,
      })
      .then((response) => {
        localStorage.setItem("post", JSON.stringify(response.data));
      });
  }, [post?.id, token]);
  console.log(post);
  useEffect(() => {
    axios
      .get(`${baseUrl}/api/posts/${post.id}/comments`, {
        headers,
      })
      .then((response) => {
        setComments(response.data);
        console.log(response.data);
        localStorage.setItem("comments", JSON.stringify(response.data));
      });
  }, [post?.id, token]);
  const addComment = () => {
    axios
      .post(
        `${baseUrl}/api/comments`,
        {
          content: newComment,
          postId: post.id,
          userId: user.id,
        },
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          const commentToAdd = {
            content: newComment,
            username: response.data.username,
          };
          setComments([...comments, commentToAdd]);
          setNewComment("");
        }
      });
  };
  const deleteComment = (id) => {
    axios
      .delete(`${baseUrl}/api/comments/${id}`, {
        headers,
      })
      .then(() => {
        setComments(
          comments.filter((val) => {
            return val.id !== id;
          })
        );
      });
  };
  // const conf = window.confirm('Etes vous sur de vouloir Supprimer definitivement votre compte ?')
  // if(conf)
  const deletePost = (id) => {
    axios
      .delete(`${baseUrl}/api/posts/${id}`, {
        headers,
      })

      .then(() => {
        window.confirm(
          "Etes vous sur de vouloir Supprimer definitivement votre POST ?"
        );
        window.location.reload();
        history.push("/");
      });
  };
  async function handleUpdateProfilePhoto(data) {
    const formData = new FormData();
    formData.append("image", data.image[0]);
    formData.append("video", data.video);
    console.log(data.image[0]);
    const sendPhoto = await fetch(`${baseUrl}/api/posts/upimg/` + post.id, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });
    const response = await sendPhoto.json();
    console.log(response);
    getUserData();
    setShowUpdatePhoto(true);
    if (sendPhoto.ok) {
      console.log(response);
      setTimeout(() => {
        history.push(window.location.reload());
      }, 300);
    }
  }
  async function getUserData() {
    // const URL = `${baseUrl}/api/users/${user.id}`;
    const data = await fetch(`${baseUrl}/api/users/`, {
      headers,
    });
    const response = await data.json();
    setData(response);
    setIsLoaded(true);
    setError(error);
    console.log(response);
  }
  useEffect(() => {
    getUserData();
  }, []); 
  function handleShowComment(e) {
    setShowComment(!showComment);
  }

  let userAuth;
  if (post?.userId === user.id) {
    userAuth = (
      <div className="post-button">
        <div className="postBottom">
          <div className="postBottomLeft">
            <i
              className="fa fa-camera fa-x2"
              onClick={() => {
                setShowUpdatePhoto(!showUpdatePhoto);
              }}
            ></i>
            {showUpdatePhoto && (
              <UpdateProfilePhoto
                submit={handleSubmit(handleUpdateProfilePhoto)}
                register={register({ required: true })}
              />
            )}
          </div>
        </div>
        <div className="postBottomRight">
          <span className="postCommentText"></span>
          <i
            className="fa fa-trash"
            onClick={() => {
              deletePost(post.id);
            }}
          />
        </div>
      </div>
    );
  } else if (!!user.isAdmin === true) {
    userAuth = <div className="post-button">xx</div>;
  }
  return (
    <div className="post">
      <div className="postWrapper">
        <div className="media-wrapper">
          <div className="">
            <div className="postTop">
              <div className="postTopRight">
                <span className="postDate">{post?.createdAt}</span>
                <p className="postDate"> Posté le {post?.createdAt}</p>
              </div>
            </div>
            <div className="media-wrapper">
              <div className="DiscoverStory">
                <div className="contextCard">
                  {" "}
                  <div className="postTopLeft">
                    <span className="postUsername">
                      {/* {users.filter((u) => u.id === post?.userId)[0].username} */}
                    </span>
                    {users.map((user) => {
                      if (post.userId === user.id) {
                        return (
                          <div key={user.id + post.id}>
                            {/* <h2 key={"h2" + user.id}>Publié par <Link to={"/users/" + user.id} key={user.id + post.id} className="nav-link">{user.username} vvv</Link></h2> */}
                            <img
                              className="postProfileImg"
                              src={
                                user.profilePicture
                                  ? `${baseUrl}/images/` + user.profilePicture
                                  : `${baseUrl}/images/` + "noAvatar.png"
                              }
                              alt="center"
                            />

                            {user.username}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="sidebare">
                <ul>
                  <MenuDots className=" white" />
                  <i
                    // className="likeIcon"
                    onClick={likeHandler}
                    className="fas fa-heart white"
                  >
                    {like}
                  </i>
                  <i className="fas fa-envelope white"></i>
                  <i className="fas  fa-play white"></i>

                  <div>
                    {/* <img
                        className="likeIcon"
                        src="/assets/icon/like.png"
                        onClick={likeHandler}
                        alt=""
                      /> */}
                  </div>
                </ul>
              </div>
              {
                // <img
                //   className="postImg"
                //   src={
                //     post.img ? `${baseUrl}/images` + post.img : "/assets/1.png"
                //   }
                //   alt="center"
                // />
                <video
                  className="postImg"
                  src={`${baseUrl}/images/` + "2.jpeg"}
                  controls
                  poster={
                    post?.img ? (
                      `${baseUrl}/images/` + post.img
                    ) : (
                      <video
                        className="postProfileVideo"
                        src={`${baseUrl}/images/` + "3.mp4"}
                        controls
                        poster={
                          post?.img
                            ? `${baseUrl}/images/` + post.img
                            : `${baseUrl}/images/` + "2.mp4"
                        }
                      />
                    )
                  }
                />
                // <video
                //   className="postImg"
                //   src={`${baseUrl}/images/` + "6.jpeg"}
                //   controls
                //   poster={
                //     post?.img ? (
                //       `${baseUrl}/images/` + post.img
                //     ) : (
                //       <video
                //         className="postImg"
                //         src={`${baseUrl}/images/` + "6.jpeg"}
                //         controls
                //         poster={
                //           post?.img
                //             ? `${baseUrl}/images/` + post.img
                //             : `${baseUrl}/images/` + "6.jpeg"
                //         }
                //       />
                //     )
                //   }
                // />
              }
            </div>
          </div>
          {userAuth}
          <hr />
          <h3>{data.like}</h3>
          {/* {showComment.shown ? <Comment />
                        : ""} */}{" "}
          {showComment.shown ? (
            <div className="listOfComments">
              <div className="card-comment">
                <form>
                  <input
                    className=""
                    id="comm"
                    type="text"
                    name="comment"
                    placeholder="Laisser un commentaire "
                    autoComplete="off"
                    value={newComment}
                    onChange={(event) => {
                      setNewComment(event.target.value);
                    }}
                  />
                  {/* <input type="text" placeholder="Mettre a jour "        onChange={(event) => {
                                  setUpdateComment(event.target.value);
                                }}value={updateComment}/> */}
                </form>
                <div className="card-reaction">
                  <button id="comm" onClick={addComment}>
                    {" "}
                    Add Comment
                  </button>
                  {/* <button id="comm" onClick={handleUpdateComment}> update Comment</button> */}
                </div>
              </div>
              <ul className="comments">
                {comments.map((comment, key) => {
                  return (
                    <li key={key} className="comment">
                      {comment.content}
                      {showComment &&
                        user.id === <div className="listOfComments"></div> && (
                          <div
                            className="update__container"
                            key={comment.id}
                          ></div>
                        )}
                      {
                        <Cancel
                          className="shareIcon"
                          onClick={() => {
                            deleteComment(comment.id);
                          }}
                        />
                      }
                      <Chat
                        title="Modification"
                        onClick={handleShowComment}
                        data-id={comment.id}
                      ></Chat>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="topbarIcon">
        <div className="topbarIconItem">
          <Chat onClick={() => setShowComment({ shown: !showComment.shown })} />
          <span className="topbarIconBadge">{comments.length}</span>
        </div>
      </div>
      <hr />
    </div>
  );
}
