
import React from "react";


// import { useAuthDispatch, useAuthState } from "../../context/auth";
// import { useMessageDispatch } from "../../context/message";
// import { getUsers, getMessages } from "../../api";


import { baseUrl } from './../../App';

import Rightbar from './../../components/rightbar/Rightbar';
import Sidebar from './../../sidebar/Sidebar';
import "../../App.css";
import "./home.css";
import Feed from "../../components/feed/Feed";
// import VideoCall from "../messages/videoCall";

import { useAuthDispatch, useAuthState } from "../../context/auth";
// import { useMessageDispatch } from "../../context/message";

import axios from "axios";
import ChatOnline from "../../components/chatOnline/ChatOnline";
import SidePost from './../../components/sidePost/SidePost';
import Messenger from "../messenger/Messenger";








export const Home = () => {

  const { user } = useAuthState();
  const { state: authState } = useAuthState();

  const token = localStorage.getItem("token");
  const headers = new Headers({
    "Accept": "application/json",
    "Authorization": `Bearer ${token}`
  });
  const initialState = {
    posts: [],
    isFetching: false,
    hasError: false,
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_POSTS_REQUEST":
        return {
          ...state,
          isFetching: true,
          hasError: false,
        };
      case "FETCH_POSTS_SUCCESS":
        return {
          ...state,
          isFetching: false,
          posts: action.payload,
        };
      case "FETCH_POSTS_FAILURE":
        return {
          ...state,
          hasError: true,
          isFetching: false,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
 
 
  // const logout = () => {
  //   useAuthDispatch({ type: "LOGOUT" });
  //   window.location.href = "/login";
  // };
  const useLogout = () => {
    const authDispatch = useAuthDispatch();

    const logout = () => {
      authDispatch({ type: "LOGOUT" });
      window.location.href = "/login";
    };

    return logout;
  };

  React.useEffect(() => {
    dispatch({
      type: "FETCH_POSTS_REQUEST",
    });
    fetch(`${baseUrl}/api/posts/`, {
      headers

    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatch({
          type: "FETCH_POSTS_SUCCESS",
          payload: resJson,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "FETCH_POSTS_FAILURE",
        });
      });
  }, [authState?.token]);
  return (
    <div >
      {/* <Topbar /> */}
      {/* <VideoCall /> */}
      {/* <div className="homeContainer">
        <div className="sid">
          <Sidebar />


        </div>


        < Feed />
        <div className="ritgh">
          <Rightbar />
        </div>
      </div> */}
      {/* <Topbar messages={messages} users={users} /> */}
      {/* <div className="homeContainer"> */}
      <div >


        <div className="row ">
          <Messenger/>
          {/* <div className="col-sm-4 sid">
          <Sidebar />

            {/* <Users users={users} messages={messages} /> */}
            {/* <ChatOnline /> */}

          {/* </div>  */}
          {/* <div className="col-sm-4">
            < Feed />
          </div> */}
          {/* <div className="col-sm-4">
          <SidePost />

          </div> */}
        </div>
      </div>

      {/* {state.isFetching ? (
        <span variant="link" onClick={useLogout} className="loader">LOADING...</span>
      ) : state.hasError ? (
        <span className="error">AN ERROR HAS OCCURED</span>
      ) : (
            <>

          {/* {state.posts.map((post) => (
                <Post key={post.id.toString()} post={post} />
              ))} */}


        {/* </> */}
      {/* )} */} 
    </div>

  );
};
export default Home;