
import React, { useState, useEffect, useRef, Fragment } from "react";
import { ListGroup, Badge, Spinner, Alert, Form, Col } from "react-bootstrap";
import { io } from "socket.io-client";
import axios from "axios";
import { useAuthState } from "../../context/auth";
import { useMessageDispatch, useMessageState } from "../../context/message";
import Message from "./Message";
import notificationSound from "./../../assets/notif.wav";
import { baseUrl } from "../../App";
import Users from "../Home/Users";
import Messagesentaudio from "./sound/MessageSound.mp3";
import CircularProgress from "@material-ui/core/CircularProgress";
import WelcomeDialogBox from "./WelcomeDialogBox";

import "./App.css";
import data from '@emoji-mart/data'
// import Picker from '@emoji-mart/react'
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { purple } from "@material-ui/core/colors";
import SpeechRecognition, {
  useSpeechRecognition
} from "react-speech-recognition";
import { Button } from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import logo from "./logo.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuIcon from "@material-ui/icons/Menu";
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import CopyToClipboard from "react-copy-to-clipboard";
import { MentionsInput, Mention } from "react-mentions";
// import reactReveal from "react-reveal";
import useLocalStorage from "./customHooks/useLocalStorage";
import Keyboard from "react-simple-keyboard";
// import Pick from 'react-giphy-picker'

import Geolocation from "react-geolocation";
// import SelectSearch from 'react-select-search';
const Messages = () => {
  // const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [username, setUsername] = useLocalStorage("username", "");
  const [uid, setUid] = useLocalStorage("uid", "");
  const [openWelcomeDialogBox, setOpenWelcomeDialogBox] = useState(false);
  const [dark, setDark] = useLocalStorage("dark", false);
  const messagesEndRef = useRef(null);
  const inputElement = useRef(null);
  const [click, setClick] = useState(false);
  const [showEmojis, setshowEmojis] = useState(false);
  const [showKeyboard, setshowKeybord] = useState(false);
  const { finalTranscript, resetTranscript } = useSpeechRecognition();
  const [showAlert, setShowAlert] = useState(false);
  const [messageCount, setMessageCount] = useState(3);
  const [scrollTop, setScrollTop] = useState(false);
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();
  const [progress, setProgress] = useState(0);
  const [value, setValue] = useState("");
  const [status, setStatus] = useState(false);
  // const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const messageDispatch = useMessageDispatch();
  // const { messages } = useMessageState();
  const [users, setUsers] = useState([]);

  const { user } = useAuthState();
  const token = (localStorage.getItem("token"));

  // const baseUrl = "http://localhost:4000";
  const handleKeypress = (event) => {
    console.log("yes");
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      console.log("13");
      newMessage();
    }
  };
  const theme = (event) => {
    if (dark === false) {
      document.body.classList.add("dark-bg");
      setDark(true);
    } else {
      document.body.classList.remove("dark-bg");
      setDark(false);
    }
  };
  const addEmoji = (e) => {
    let emoji = e.native;
    let cursorPositionStart = inputElement.current.selectionStart;
    let newinput =
      input.slice(0, cursorPositionStart) +
      emoji +
      input.slice(cursorPositionStart);
    setInput(newinput);
    inputElement.current.focus();
  };
  const emojiToggle = (e) => {
    console.log("in emojiToggle");
    if (showEmojis === true) {
      setshowEmojis(false);
      console.log("picker not visible");
    } else {
      setshowEmojis(true);
      console.log("picker visible");
    }
  };

  const keyboardToggle = (e) => {
    console.log("in keyboardToggle");
    if (showKeyboard === true) {
      setshowKeybord(false);
      console.log("picker not visible");
    } else {
      setshowKeybord(true);
      console.log("picker visible");
    }
  };
  const loadOlderMessages = () => {
    setMessageCount((prev) => prev + 3);
    setProgress(2);
  };
  useEffect(() => {
    if (finalTranscript !== "") {
      setShowAlert(false);
      setInput(finalTranscript);
      resetTranscript();
    }
  });
  const Speechtoinput = (e) => {
    setShowAlert(true);
    SpeechRecognition.startListening();
  };
  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    //If you want to handle the shift and caps lock buttons
    if (button === "{shift}" || button === "{lock}") handleShift();
  };
  const onChange = (input) => {
    setInput(input);
    console.log("Input changed", input);
  };
  const newMessage = () => {
    if (input.trim() !== "") {
      // Create an object to represent the new message
      const messageData = {
        username: username,
        uid: uid,
        content: input,
        from: user.username,
      to: selectedUser.username,
      };
  
      // Make an Axios POST request to your custom backend endpoint to add the new message
      axios.post('http://localhost:4000/api/messages', messageData)
        .then((response) => {
          console.log('Message added successfully:', response.data);
          new Audio(Messagesentaudio).play();
        })
        .catch((error) => {
          console.error('Error adding message:', error);
        });
  
      setInput("");
    }
  };
  useEffect(() => {
    if (scrollTop) return setScrollTop(false);
    scrollToBottom();
  }, [messages]);

  const handleClick = () => setClick(!click);

  const handlelogout = () => {
    setClick(!click);
    localStorage.removeItem("RMA-username");
    setUsername("");
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = io(baseUrl, {
      auth: {
        token: `Bearer ${token}`,
      },
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to socket server");
    });

    socketRef.current.on("messageReceived", (message) => {
      messageDispatch({ type: "ADD_MESSAGE", payload: message });

      // Play notification sound
      const audio = new Audio(notificationSound);
      audio.play();
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket error:", error);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [messageDispatch, token]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/users`, {
          headers,
        });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const [content, setContent] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);

    if (content.trim() === "") {
      setError(true);
      return;
    }

    const message = {
      from: user.username,
      to: selectedUser.username,
      content: content.trim(),
    };
    const res = await axios.post(`${baseUrl}/api/messages`, message);
    setMessages((prevMessages) => [...prevMessages, { message: res.data.content, id: res.data.id,    from: user.from,
      to: res.data.to,  }])
    socketRef.current.emit("sendMessage", message);

    messageDispatch({ type: "ADD_MESSAGE", payload: message });
    setContent("");
  };

  // const formattedMessages = messages?.filter(
  //   (message) =>
  //     (message.from === user.id && message.to === selectedUser?.id) ||
  //     (message.from === selectedUser?.id && message.to === user.id)
  // );
  const [formattedMessages, setFormattedMessages] = useState([]);
  const [formattedMessage, setFormattedMessage] = useState({});
  useEffect(() => {
    const newMessages = messages.filter(
      (message) =>
        message.to === user.username || message.from === user.username
    );
    const otherUsers = newMessages.map((message) =>
      message.to === user.username ? message.from : message.to
    );
    const uniqueOtherUsers = [...new Set(otherUsers)];

    const formattedMessages = uniqueOtherUsers.map((username) => {
      const messagesWithUser = newMessages.filter(
        (message) => message.to === username || message.from === username
      );
      const lastMessage =
        messagesWithUser[messagesWithUser.length - 1]?.content;
      const formattedMessage = {
        username,
        message: {
          content: lastMessage,
          createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
        },
      };

      return formattedMessage;
    });

    setFormattedMessages(formattedMessages);
  }, [user, messages]);

  formattedMessages.forEach((formattedMessage) => {
    console.log(formattedMessage?.message.content);
  });
  // useEffect(() => {
  //   if (selectedUser) {
  //     axios
  //       .get(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers }, {
  //         params: {
  //           messageCount: messageCount,
  //         },
  //       })
  //       .then((response) => {
  //         setMessages(response.data);

  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }, [selectedUser]);
  useEffect(() => {
    if(selectedUser)
   { setLoading(true);

    // Make an Axios GET request to your custom backend endpoint
    axios.get('http://localhost:4000/api/messages/' +`${selectedUser?.username}`,{ headers }, {
      params: {
        messageCount: messageCount,
      }
    }, )
      .then((response) => {

        setMessages(response.data.reverse()); 
  // Assuming the response is an array of messages
        setLoading(false);
        setScrollTop(true);
      })
      .catch((error) => {
        console.error('Error fetching messages:', error);
        setLoading(false);
      });}
  console.log(messageCount)

  }, [messageCount,selectedUser]);
  console.log(messages)

  return (
    <>

    <div className="d-flex">


    </div>
          <div className="App">
          <div style={{ width: "250px" }}>

< h4 > Users 2</h4>
{loading ? (
  <Spinner animation="border" variant="primary" />
) : (
  <ListGroup>
    {users.map((user) => (
      <ListGroup.Item
        key={user.id}
        action
        onClick={() => handleSelectUser(user)}
        active={selectedUser?.id === user.id}
      >
        <Badge
          bg={
            selectedUser?.username === user.username
              ? user.online
                ? "primary"
                : "secondary"
              : user.unread
                ? "danger"
                : user.online
                  ? "primary"
                  : "secondary"
          }
        >
          {selectedUser?.username === user.username
            ? user.online
              ? "Online"
              : "Offline"
            : user.unread
              ? "New"
              : user.online
                ? "Online"
                : "Offline"}
        </Badge>
        {user.username}
        {formattedMessages[0]?.username === user.username ? (
          <Badge pill variant="danger" className="ml-2">
            {formattedMessages[0]?.message.content}
          </Badge>
        ) : (<Badge>Select a un Esclave </Badge>)}
      </ListGroup.Item>
    ))}
  </ListGroup>
)}
             <button
                      className={`${
                        dark ? "loadOlderMessages_dark" : "loadOlderMessages"
                      }`}
                      onClick={loadOlderMessages}
                    >
                      Load Older Messages
                    </button>
</div>
              {loading ? (
                <CircularProgress className="loading" />
              ) : (
                <>
                  <div className="scroll">
                    <LoadingBar
                      color=" red"
                      progress={progress}
                      onLoaderFinished={() => setProgress(0)}
                    />
                    <br />
                    <br />
                    <br />
 
      <h1>{username}</h1>
                   
                    <br />
                    <br />
                    {messages?.map((message, index) => (
                <Fragment key={index}>
                  <Message message={message} />
                  {index === formattedMessages.length - 1 && loading && (
                    <Spinner
                      animation="border"
                      variant="primary"
                      style={{ marginLeft: "50%", marginTop: "1rem" }}
                    />
                  )}
                </Fragment>
              ))}
                    {/* {messages.map((message) => (
                      <Messages
                        messages={message.content}
                        username={username}
                        uid={uid}
                        dark={dark}
                        key={genKey()}
                      />
                    ))} */}
           <div className="flex-grow-1">
        {selectedUser ? (
          <div className="d-flex flex-column h-100">
            <div className="flex-grow-1 overflow-auto">

            </div>
          
             <div className="div__footer">
                    <footer className={`${dark ? "footer_dark" : ""}`}>
                      <div className="content__footer">
                        <div
                          className={` ${
                            dark ? "sendNewMessagedark" : "sendNewMessage"
                          }`}
                        >
                          <button
                            className={`addfiles ${dark ? "darkButton" : ""}`}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button className="EmojiToggle">
                            <InsertEmoticonIcon onClick={emojiToggle} />
                          </button>
                          {showEmojis && (
                            <span
                              className={`${
                                dark ? "EmojiPicker_dark" : "EmojiPicker"
                              }`}
                            >
                              {/* <Picker    data={data}
                   onEmojiSelect={addEmoji}/> */}
                            </span>
                          )}
                          <button className="KeyboardToggle">
                            <i
                              className="fa fa-keyboard-o"
                              onClick={keyboardToggle}
                            ></i>
                          </button>
                          {showKeyboard && (
                            <span
                              className={`${
                                dark ? "KeyboardPicker_dark" : "KeyboardPicker"
                              }`}
                            >
                              {/* <input
                                                                onChange={onChangeInput}
                                                            /> */}
                              <Keyboard
                                keyboardRef={(r) => (keyboard.current = r)}
                                layoutName={layout}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                              />
                            </span>
                          )}
                          {/* <input
                            type="text"
                            onChange={(e) => {
                              setContent(e.target.value);
                              
                              setStatus(false);
                            }}
                            onSubmit={handleSubmit}
                          
                            // type="text"
                            className="rounded-pill"
                            placeholder="Enter your message..."
                            value={content}
                            // onChange={(e) => setContent(e.target.value)}
                            isInvalid={error}
                          /> */}
                          
                          <input
                            ref={inputElement}
                            // className={`input ${
                            //   dark ? "dark_input" : "light_input"
                            // }`}
                            className="rounded-pill"
                            type="text"
                            placeholder="Type a message"
                            onChange={(event) => setInput(event.target.value)}
                            onKeyPress={handleKeypress}
                            value={input}
                          />
                          
                          <CopyToClipboard
                            text={value}
                            onCopy={() => setStatus(true)}
                          >
                            <button className="copy">
                              {" "}
                              <i className="fa fa-copy"></i>
                            </button>
                          </CopyToClipboard>
                          {status && (
                            <p className="copied_status"> Copied!!!</p>
                          )}

                          <div className="speak">
                            <button onClick={Speechtoinput}>
                              <i className="fa fa-microphone"></i>
                            </button>
                            {showAlert && (
                              <span className="Speaknow_alert">Speak now</span>
                            )}
                          </div>
                              <Geolocation >  </Geolocation>
                          <button
                            className={`btnsend ${
                              dark ? "darkButtonSend" : ""
                            }`}
                            id="sendMsgBtn"
                            type="submit"
                            variant="contained"
                            crossOrigin="anonymous"
                            onClick={newMessage}
                          >
                            <i className="fa fa-paper-plane"></i>
                          </button>
                        </div>
                      </div>
                    </footer>
                    <div
                      className={dark ? "scrolltobottomdark" : "scrolltobottom"}
                    >
                      <Button title="scroll to bottom" onClick={scrollToBottom}>
                        <KeyboardArrowDownIcon
                          className={dark ? "scrollicondark" : "scrollicon"}
                          style={{ width: "20px", height: "40px" }}
                        />
                      </Button>
                    </div>
                    <WelcomeDialogBox
                      className={dark ? "wlcm_dark" : "wlcm_light"}
                      open={openWelcomeDialogBox}
                      close={() => setOpenWelcomeDialogBox(false)}
                      setUsername={setUsername}

                      setUid={setUid}
                    />
                  </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center">
              <Alert variant="info">Select a user to start messaging</Alert>
            </div>
          </div>
        )}
      </div>
                    <div />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>

                  <div ref={messagesEndRef} />
                  <Mention trigger="@" />
                 
                </>
              )}
            </div>
    </>
  );
};
const genKey = (function () {
  var keyCode = 0;
  return function incKey() {
    return keyCode++;
  };
})();

export default Messages;
