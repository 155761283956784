import React from "react";

import { MoreVert } from "@material-ui/icons";

import { useState, useEffect } from "react";
import { useRef } from "react";
import { useAuthDispatch, useAuthState } from "../../context/auth";

const  MenuDots = (props) => {
  const { user } = useAuthState();
  // const { state, dispatch } = React.useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const dropdownwrapper = useRef(null);
  const handleClickOutside = (event) => {
    if (
      dropdownwrapper.current &&
      !dropdownwrapper.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <div className="dropdownwrapper" ref={dropdownwrapper}>
      <MoreVert onClick={() => setOpen(!open)} {...props} />
      {open && (
        <div>
          <div className="modelwrapper">
            <ul className="modal__content  modalx ">
              <li>
                <a className="dropdown-menu__item" href="/">
                  {" "}
                  Home
                </a>
              </li>
              <li>
                {" "}
                <a className="dropdown-menu__item" href={"/admin/" + user.id}>
                  admin
                </a>{" "}
              </li>
              <li className="dropdown-menu__item">Hi {user.username} (X) </li>
              <li
                className="dropdown-menu__item"
                onClick={() =>
                  useAuthDispatch({
                    type: "LOGOUT",
                  })
                }
              >
                Deconexion
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
MenuDots.displayName ="MenuDots";
export default MenuDots;