// import React, { useReducer, useState, useEffect } from 'react';

// import { Container } from "react-bootstrap";
// import { BrowserRouter, Switch } from "react-router-dom";



// import "./App.scss";

// import Register from "./pages/register/register";
// import Login from "./pages/login/login";
// import Home from "./pages/Home/home";

// import { AuthProvider } from "./context/auth";
// import { MessageProvider } from "./context/message";
// import DynamicRoute from "./util/DynamicRoute";
// import Messenger from './pages/messenger/Messenger';
// import Messages from './pages/messages/Messages';
// import Profile from './pages/profile/Profile';
// import AdminPage from './pages/profile/AdminPage';
// import UpdateProfilePhoto from './pages/profile/UpdateProfilePhoto';
// import DeleteUser from './pages/profile/DeleteUser';
// import Topbar from './components/topbar/Topbar';
// import Chat from './pages/messages/chat';
// import { classNames } from 'classnames';
// export const AuthContext = React.createContext();

// const token = (localStorage.getItem("token"));
// const user = JSON.parse(localStorage.getItem("user"));
// const headers = {
//   Accept: "application/json",
//   Authorization: `Bearer ${token}`,
// };
// const nodeEnv = process.env.NODE_ENV;
// let ioUrl;

// if (nodeEnv === 'production') {
//   ioUrl = process.env.REACT_APP_IO_URL || 'https://sds-2a76.onrender.com';
// } else {
//   ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
// }

// export const baseUrl = ioUrl;


// const initialState = {
//   isAuthenticated: false,



//   isFetching: false,
//   isAdmin: false,
//   user: localStorage.getItem("user") || null,
//   // admin: JSON.parse(localStorage.getItem("admin")) || null,
//   token: null,
// };
// const reducer = (state, action) => {
//   switch (action.type) {
//     case "LOGIN":
//       localStorage.setItem("user", (action.payload.user));
//       localStorage.setItem("token", JSON.stringify(action.payload.token));
//       return {
//         ...state,
//         isAdmin: true,
//         isAuthenticated: true,
//         user: action.payload.user,
//         token: action.payload.token,
//         isFetching: action.payload.isFetching,
//       };
//     case "LOGOUT":
//       localStorage.removeItem("user");
//       localStorage.removeItem("token");
//       return {
//         ...state,
//         isAuthenticated: false,
//         user: null,
//         token: null,
//         isFetching: action.payload.isFetching,
//       };
//     default:
//       return state;
//   }
// };
// function App({ selectedUser }) {
//   const [messages, setMessages] = useState([]);
//   const [users, setUsers] = useState([]);
//   // const [selectedUser, setSelectedUser] = useState(null);

//   const [state, dispatch] = React.useReducer(reducer, initialState);
//   fetch(`${baseUrl}/api/users`, { headers })
//     .then((response) => response.json())
//     .then((data) => {

//       // Pass the messages data to the Topbar component
//       // setUsers(data);
//       // setSelectedUser(data);// Assuming users data is received along with messages
//     })
//     .catch((error) => console.log(error));
//   useEffect(() => {
//     if (selectedUser)
//       fetch(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
//         .then((response) => response.json())
//         .then((data) => setMessages(data))



//         .catch((error) => console.log(error));


//   }, [selectedUser]);
//   console.log(users)
//   React.useEffect(() => {
//     const user = (localStorage.getItem("user"));
//     const token = (localStorage.getItem("token"));
//     if (user && token) {
//       dispatch({
//         type: "LOGIN",
//         payload: {
//           user,
//           token,

//         },
//       });
//     }
//   }, []);


//   // const handleUserClick = (user) => {
//   //   setSelectedUser(user);
//   //   setError("");
//   // };

//   return (

//     <AuthProvider >
//       <MessageProvider>
//         <BrowserRouter>

//           <Topbar messages={messages} users={users} />


//           {/* we use the exact keyword as if not then it will look for anything after the '/' in path */}
//           <Switch>

//             <DynamicRoute exact path="/" component={Home} authenticated />
//             <DynamicRoute path="/register" component={() => (<div className="app-container">
//               <div className="left-image">
//                 <img src="/assets/1.png" alt="Smartphone" />
//               </div>
//               <div className="right-content">
//                 <div className="right-image">
//                   <Register guest />
//                 </div>
//               </div>
//             </div>)} guest />
//             <DynamicRoute path="/profile/:username">
//               <Profile />
//             </DynamicRoute>

//             <DynamicRoute path="/admin" component={AdminPage} authenticated />
//             <DynamicRoute path="/deleteuser/:id">
//               <DeleteUser />
//             </DynamicRoute>
//             <DynamicRoute path="/updateprofilephoto">
//               <UpdateProfilePhoto />
//             </DynamicRoute>
//             <DynamicRoute path="/messenger" component={Messenger} authenticated />
//             <DynamicRoute path="/messages" component={Messages} authenticated />
//             <DynamicRoute path="/chat" component={Chat} authenticated />
//             <DynamicRoute path="/login" component={Login} guest />
//           </Switch>


//         </BrowserRouter>
//       </MessageProvider>
//     </AuthProvider>

//   );
// }

// export default App;
import React, {  useState, useEffect } from 'react';

// import { Container } from "react-bootstrap";
import { BrowserRouter, Switch } from "react-router-dom";



import "./App.scss";

import Register from "./pages/register/register";
import Login from "./pages/login/login";
import Home from "./pages/Home/home";

import { AuthProvider } from "./context/auth";
import { MessageProvider } from "./context/message";
import DynamicRoute from "./util/DynamicRoute";
import Messenger from './pages/messenger/Messenger';
import Messages from './pages/messages/Messages';
import Profile from './pages/profile/Profile';
import AdminPage from './pages/profile/AdminPage';
import UpdateProfilePhoto from './pages/profile/UpdateProfilePhoto';
import DeleteUser from './pages/profile/DeleteUser';
import Topbar from './components/topbar/Topbar';
import Chat from './pages/messages/chat';
import NotFoundView from './pages/NotFoundView';

const token = localStorage.getItem("token");
// const user = JSON.parse(localStorage.getItem("user"));


// const initialState = {
//   isAuthenticated: false,
//   isFetching: false,
//   isAdmin: false,
//   user: localStorage.getItem("user") || null,
//   token: null,
// };

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "LOGIN":
//       localStorage.setItem("user", action.payload.user);
//       localStorage.setItem("token", JSON.stringify(action.payload.token));
//       return {
//         ...state,
//         isAdmin: true,
//         isAuthenticated: true,
//         user: action.payload.user,
//         token: action.payload.token,
//         isFetching: action.payload.isFetching,
//       };
//     case "LOGOUT":
//       localStorage.removeItem("user");
//       localStorage.removeItem("token");
//       return {
//         ...state,
//         isAuthenticated: false,
//         user: null,
//         token: null,
//         isFetching: action.payload.isFetching,
//       };
//     default:
//       return state;
//   }
// };
export const AuthContext = React.createContext();
const headers = {
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
};
const nodeEnv = process.env.NODE_ENV;
let ioUrl;

if (nodeEnv === 'production') {
  ioUrl = process.env.REACT_APP_IO_URL || 'https://sds-2a76.onrender.com';
} else {
  ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
}

export const baseUrl = ioUrl;
function App() {
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  // const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {

    fetch(`${baseUrl}/api/users`, { headers })
      .then((response) => response.json())
      .then((data) => setUsers(data))

      .catch((error) => console.log(error));


  }, []);
  useEffect(() => {
    if (selectedUser) {
      fetch(`${baseUrl}/api/messages/${selectedUser?.username}`, { headers })
        .then((response) => response.json())
        .then((data) => setMessages(data))
        .catch((error) => console.log(error));
    }
  }, [selectedUser]);
  console.log(messages)
  return (

    <AuthProvider >
      <MessageProvider>
        <BrowserRouter>
          <div > <Topbar messages={messages} users={users} selectedUser={selectedUser}
            setSelectedUser={setSelectedUser} /></div>



          {/* we use the exact keyword as if not then it will look for anything after the '/' in path */}
          <Switch>

            <DynamicRoute exact path="/" component={Home} authenticated />
            <DynamicRoute path="/register" component={() => (<div className="app-container">
              <div className="left-image">
                <img src="/assets/1.png" alt="Smartphone" />
              </div>
              <div className="right-content">
                <div className="right-image">
                  <Register guest />
                </div>
              </div>
            </div>)} guest />

            <DynamicRoute path="/login" component={Login} guest />
            <DynamicRoute path="/profile/:username">
<Profile />
</DynamicRoute>

<DynamicRoute path="/admin" component={AdminPage} authenticated />
<DynamicRoute path="/deleteuser/:id">
<DeleteUser />
</DynamicRoute>
<DynamicRoute path="/updateprofilephoto">
<UpdateProfilePhoto />
</DynamicRoute>
<DynamicRoute id="messenger" path="/messenger" component={Messenger} authenticated />
<DynamicRoute path="/messages" component={Messages} authenticated />
<DynamicRoute path="/chat" component={Chat} authenticated /> 
<DynamicRoute path="*" element={<NotFoundView />} />
          </Switch>


        </BrowserRouter>
      </MessageProvider>
    </AuthProvider>

  );
}

export default App;
{/* <DynamicRoute path="/profile/:username">
<Profile />
</DynamicRoute>

<DynamicRoute path="/admin" component={AdminPage} authenticated />
<DynamicRoute path="/deleteuser/:id">
<DeleteUser />
</DynamicRoute>
<DynamicRoute path="/updateprofilephoto">
<UpdateProfilePhoto />
</DynamicRoute>
<DynamicRoute id="messenger" path="/messenger" component={Messenger} authenticated />
<DynamicRoute path="/messages" component={Messages} authenticated />
<DynamicRoute path="/chat" component={Chat} authenticated /> */}