import React, { useEffect, Fragment, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { io } from "socket.io-client";
import Message from "./Message";
import axios from "axios";
import { baseUrl } from '../../App';
import { PropagateLoader } from 'react-spinners';
import { useAuthDispatch, useAuthState } from "../../context/auth";
import { useMessageDispatch } from "../../context/message";
import Spinner from '../../components/spinner/Spinner';
import { ChatBubbleOutline } from "@mui/icons-material";

function Chat({ history }) {
    // const token = localStorage.getItem("token");
    const token = (localStorage.getItem("token"));
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const authDispatch = useAuthDispatch();
    const messageDispatch = useMessageDispatch();

    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [formattedMessages, setFormattedMessages] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    const [content, setContent] = useState("");
    const [error, setError] = useState("");
    const [socket, setSocket] = useState(null);

    const headers = new Headers({
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
    });

    useEffect(() => {
        getUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => console.log(error));
    }, []);

    const NEW_MESSAGE = {};

    // here we are aliasing the data and error variables
    const { data: messageData, error: messageError } = NEW_MESSAGE;

    useEffect(() => {
        if (messageError) console.log(messageError);

        if (messageData) {
            const message = messageData.newMessage;
            const otherUser =
                user.username === message.to ? message.from : message.to;

            messageDispatch({
                type: "ADD_MESSAGE",
                payload: {
                    username: otherUser,
                    message,
                },
            });
        }
    }, [messageError, messageData, user.username, messageDispatch]);

    useEffect(() => {
        const newMessages = messages?.filter(
            (message) => message.to === user.username || message.from === user.username
        );
        const otherUsers = newMessages?.map((message) =>
            message.to === user.username ? message.from : message.to
        );
        const uniqueOtherUsers = [...new Set(otherUsers)];

        const formattedMessages = uniqueOtherUsers?.map((username) => {
            const messagesWithUser = newMessages.filter(
                (message) => message.to === username || message.from === username
            );
            const lastMessage =
                messagesWithUser[messagesWithUser.length - 1]?.content;
            const formattedMessage = {
                username,
                message: {
                    content: lastMessage,
                    createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
                },
            };
            console.log(formattedMessage)
            return formattedMessage;
        });

        // Sort the formatted messages by the latest message createdAt timestamp
        const sortedMessages = formattedMessages.sort((a, b) => {
            const aTimestamp = a.message.createdAt || 0;
            const bTimestamp = b.message.createdAt || 0;
            return bTimestamp - aTimestamp;
        });

        setFormattedMessages(sortedMessages);
        setFormattedMessages(formattedMessages);
    }, [messages]);

    const logout = () => {
        authDispatch({ type: "LOGOUT" });
        window.location.href = "/login";
    };

    const getUsers = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/users/`, { headers });
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.log
                (error);
        }
    };

    useEffect(() => {
        getUsers();
        //ws?token=${token}/
        const ws = io("ws://localhost:4000");
        setSocket(ws);
        ws.addEventListener("message", (event) => {
            const message = JSON.parse(event.data);
            setMessages((prevMessages) => [...prevMessages, message]);
            setLoading(false);
        });

        return () => {
            ws.close();
        };
    }, []);

    useEffect(() => {
        if (selectedUser) {
            fetch(`${baseUrl}/api/messages/${selectedUser.username}`, { headers })
                .then((response) => response.json())
                .then((data) => setMessages(data))
                .catch((error) => console.log(error));

        }
    }, [selectedUser]);

    const submitMessage = async (e) => {
        e.preventDefault();
        if (!selectedUser) {
            setError("Recipient is required");
            return;
        }

        if (content.trim() === "") {
            setError("Message content is required");
            return;
        }

        try {
            const message = { from: user.username, to: selectedUser.username, content };
            socket.send(JSON.stringify(message));
            setContent("");
            setError("");
            const res = await axios.post(`${baseUrl}/api/messages`, message);
            setMessages((prevMessages) => [...prevMessages, res.data]);
        } catch (error) {
            console.log(error);
            setError("Failed to send message");
        }
    };

    let selectedChatMarkup;

    if (!messages) {
        selectedChatMarkup = <p className="info-text">Select a friend</p>;
    } else if (messages.length === 0) {
        selectedChatMarkup = (
            <p className="info-text">You are now connected! Send your first message</p>
        );
    } else {
        selectedChatMarkup = messages?.map((message, index) => (
            <Fragment key={message.id}>
                <Message message={message} />
                {index === messages.length - 1 && (
                    <div className="invisible">
                        <hr className="m-0" />
                    </div>
                )}
            </Fragment>
        ));
    }

    return (
        <Col xs={10} md={8}>
            {/* <div>
                {loading ? (
                    <div className="spinner-container">
                        <ChatBubbleOutline color="#123abc" />

                    </div>
                ) : (
                    <div>

                        {messages?.map((message, index) => (
                            <div key={index}>
                                <div>{message}</div>
                                <div className="invisible">
                                    <hr className="m-0" />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div> */}
            <div className="messages-box d-flex flex-column-reverse">
                {selectedUser ? (
                    selectedChatMarkup
                ) : (
                    <p className="info-text">Select a friend</p>
                )}
            </div>
            <div>
                <Form onSubmit={submitMessage}>
                    <Form.Group className="my-3 d-flex align-items-center">
                        <Form.Control
                            type="text"
                            className="message-input p-2 rounded-pill bg-secondary border-0"
                            placeholder="type a message ..."
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                        <i
                            className="fas fa-paper-plane fa-2x text-primary mx-2"
                            onClick={submitMessage}
                            role="button"
                        ></i>
                    </Form.Group>
                </Form>
            </div>
            <div className="users-list">
                <h5 className="mb-3">Friends</h5>
                {users?.map((user) => (
                    <div
                        key={user.id}
                        className={`user ${selectedUser?.username === user.username ? "selected" : ""}`}
                        onClick={() => setSelectedUser(user)}
                        role="button"
                    >
                        <div className="user-info">
                            <div className="username">{user.username}</div>
                            {formattedMessages[0]?.username === user.username ? (
                                <>
                                    <span className="content">{formattedMessages[0]?.message.content}</span>
                                </>
                            ) : (
                                <span className="no-message"><Spinner /></span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Col>
    );
}

export default Chat;
// import React from "react";

// import "./App.css";
// import { useState, useEffect, useRef, lazy, Suspense } from "react";
// import { Button } from "@material-ui/core";
// import Brightness4Icon from "@material-ui/icons/Brightness4";
// import logo from "./logo.png";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import MenuIcon from "@material-ui/icons/Menu";
// import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";
// import Messagesentaudio from "./sound/MessageSound.mp3";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import WelcomeDialogBox from "./WelcomeDialogBox";
// //  import db from "./datadb.js";
// // import firebase from "firebase";
// // import "emoji-mart/css/emoji-mart.css";
// import { Picker } from "emoji-mart";
// import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
// import { purple } from "@material-ui/core/colors";
// import SpeechRecognition, {
//   useSpeechRecognition
// } from "react-speech-recognition";
// // import Login from "./components/login/login";
// // import Signup from "./components/signup/signup";
// import Forget from "./forgetpass/forget";
// import Reset from "./resetpass/reset";
// import Keyboard from "react-simple-keyboard";
// import "react-simple-keyboard/build/css/index.css";
// import useLocalStorage from "./customHooks/useLocalStorage";
// // import About from "./about-us/About";
// import Footer from "./footer/footer";
// // import ContactUs from "./contactForm/contactForm.js";
// import Messages from "./messages/Messages.js";
// import Landing from "./Landingpage/LandingPage";
// import Faq from "./faq/faq";
// import Features from "./Featurespage/FeaturesPage";
// import axios from "axios"
// import LoadingBar from "react-top-loading-bar";
// import CopyToClipboard from "react-copy-to-clipboard";
// import { MentionsInput, Mention } from "react-mentions";
// // import reactReveal from "react-reveal";

// // import Pick from 'react-giphy-picker'

// import Geolocation from "react-geolocation";
// // import SelectSearch from 'react-select-search';



// function Chat() {
//    const users = JSON.parse(localStorage.getItem("USERS"));

//   const [loading, setLoading] = useState(false);
//   const [input, setInput] = useState("");
//   const [messages, setMessages] = useState([]);
//   const [username, setUsername] = useLocalStorage("username", "");
//   const [uid, setUid] = useLocalStorage("uid", "");
//   const [openWelcomeDialogBox, setOpenWelcomeDialogBox] = useState(false);
//   const [dark, setDark] = useLocalStorage("dark", false);
//   const messagesEndRef = useRef(null);
//   const inputElement = useRef(null);
//   const [click, setClick] = useState(false);
//   const [showEmojis, setshowEmojis] = useState(false);
//   const [showKeyboard, setshowKeybord] = useState(false);
//   const { finalTranscript, resetTranscript } = useSpeechRecognition();
//   const [showAlert, setShowAlert] = useState(false);
//   const [messageCount, setMessageCount] = useState(50);
//   const [scrollTop, setScrollTop] = useState(false);
//   const [layout, setLayout] = useState("default");
//   const keyboard = useRef();
//   const [progress, setProgress] = useState(0);
//   const [value, setValue] = useState("");
//   const [status, setStatus] = useState(false);
//   const [selectedUser, setSelectedUser] = useState(null);

//   useEffect(() => {
//     if (!username || !uid) setOpenWelcomeDialogBox(true);
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("messages", messages);
//   }, [messages]);

//   useEffect(() => {
//     if(selectedUser)
//    { setLoading(true);

//     // Make an Axios GET request to your custom backend endpoint
//     axios.get('http://localhost:4000/api/messages/' +`${username}`, {
//       params: {
//         messageCount: messageCount,
//       },
//     })
//       .then((response) => {

//         setMessages(response.data.reverse()); 
//   // Assuming the response is an array of messages
//         setLoading(false);
//         setScrollTop(true);
//       })
//       .catch((error) => {
//         console.error('Error fetching messages:', error);
//         setLoading(false);
//       });}
//   }, [messageCount,selectedUser]);
//   console.log(messages)

//   useEffect(() => {
//     if (scrollTop) return setScrollTop(false);
//     scrollToBottom();
//   }, [messages]);

//   const handleClick = () => setClick(!click);

//   const handlelogout = () => {
//     setClick(!click);
//     localStorage.removeItem("RMA-username");
//     setUsername("");
//   };

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
//   };

//   // const newMessage = (event) => {
//   //   if (input.trim() !== "") {
//   //     db.collection("messages").add({
//   //       username: username,
//   //       uid: uid,
//   //       message: input,
//   //       // timestamp: firebase.firestore.FieldValue.serverTimestamp()
//   //     });
//   //     new Audio(Messagesentaudio).play();
//   //   }
//   //   setInput("");
//   // };
//   const newMessage = () => {
//     if (input.trim() !== "") {
//       // Create an object to represent the new message
//       const messageData = {
//         username: username,
//         uid: uid,
//         content: input,
//         to:username,
//         from:username
//       };
  
//       // Make an Axios POST request to your custom backend endpoint to add the new message
//       axios.post('http://localhost:4000/api/messages', messageData)
//         .then((response) => {
//           console.log('Message added successfully:', response.data);
//           new Audio(Messagesentaudio).play();
//         })
//         .catch((error) => {
//           console.error('Error adding message:', error);
//         });
  
//       setInput("");
//     }
//   };
  
//   const handleKeypress = (event) => {
//     console.log("yes");
//     //it triggers by pressing the enter key
//     if (event.key === "Enter") {
//       console.log("13");
//       newMessage();
//     }
//   };
//   const theme = (event) => {
//     if (dark === false) {
//       document.body.classList.add("dark-bg");
//       setDark(true);
//     } else {
//       document.body.classList.remove("dark-bg");
//       setDark(false);
//     }
//   };
//   const addEmoji = (e) => {
//     let emoji = e.native;
//     let cursorPositionStart = inputElement.current.selectionStart;
//     let newinput =
//       input.slice(0, cursorPositionStart) +
//       emoji +
//       input.slice(cursorPositionStart);
//     setInput(newinput);
//     inputElement.current.focus();
//   };
//   const emojiToggle = (e) => {
//     console.log("in emojiToggle");
//     if (showEmojis === true) {
//       setshowEmojis(false);
//       console.log("picker not visible");
//     } else {
//       setshowEmojis(true);
//       console.log("picker visible");
//     }
//   };

//   const keyboardToggle = (e) => {
//     console.log("in keyboardToggle");
//     if (showKeyboard === true) {
//       setshowKeybord(false);
//       console.log("picker not visible");
//     } else {
//       setshowKeybord(true);
//       console.log("picker visible");
//     }
//   };
//   const loadOlderMessages = () => {
//     setMessageCount((prev) => prev + 50);
//     setProgress(100);
//   };
//   useEffect(() => {
//     if (finalTranscript !== "") {
//       setShowAlert(false);
//       setInput(finalTranscript);
//       resetTranscript();
//     }
//   });
//   const Speechtoinput = (e) => {
//     setShowAlert(true);
//     SpeechRecognition.startListening();
//   };
//   const handleShift = () => {
//     const newLayoutName = layout === "default" ? "shift" : "default";
//     setLayout(newLayoutName);
//   };

//   const onKeyPress = (button) => {
//     console.log("Button pressed", button);
//     //If you want to handle the shift and caps lock buttons
//     if (button === "{shift}" || button === "{lock}") handleShift();
//   };
//   const onChange = (input) => {
//     setInput(input);
//     console.log("Input changed", input);
//   };

//   const onChangeInput = (event) => {
//     const input = event.target.value;
//     setInput(input);
//     keyboard.current.setInput(input);
//   };

//   return (
//     <Router>
//       {/*================ NavBar. Common across all routes ======================*/}

//       <nav className={`${dark ? "nav_dark" : "navbar"}`}>
//         <div className="nav-container">
//           <a href="/landing">
//             <img
//               className="Logo"
//               aspect-ratio="1/1"
//               height="auto"
//               width="50px"
//               src={logo}
//               alt="messenger-logo"
//             />
//           </a>
//           <h1 style={{ fontSize: "25px" }} className={`messenger`}>
//             Messenger
//           </h1>
//           <a href="/" className="nav-logo"></a>

//           <ul
//             className={click ? "nav-menu active" : "nav-menu"}
//             id={dark ? "nav-menu_dark" : "nav-menu_light"}
//           >
//             <li className="nav-item">
//               <Link
//                 to="/"
//                 activeClassName="active"
//                 className="nav-links"
//                 onClick={handleClick}
//               >
//                 Home
//               </Link>
//             </li>
//             <li className="nav-item">
//               <Link
//                 to="/features"
//                 activeClassName="active"
//                 className="nav-links"
//                 onClick={handleClick}
//               >
//                 Features
//               </Link>
//             </li>
//             <li className="nav-item">
//               <Link
//                 to="/"
//                 activeClassName="active"
//                 className="nav-links"
//                 onClick={handleClick}
//               >
//                 About Us
//               </Link>
//             </li>
//             {username && (
//               <li className="nav-item">
//                 <Link
//                   to="/landing"
//                   activeClassName="active"
//                   className="nav-links"
//                   onClick={handlelogout}
//                 >
//                   Logout
//                 </Link>
//               </li>
//             )}
//             {!username && (
//               <>
//                 <li className="nav-item">
//                   <Link
//                     to="/login"
//                     activeClassName="active"
//                     className="nav-links"
//                     onClick={handleClick}
//                   >
//                     Login
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link
//                     to="/signup"
//                     activeClassName="active"
//                     className="nav-links"
//                     onClick={handleClick}
//                   >
//                     Signup
//                   </Link>
//                 </li>
//               </>
//             )}

//             <li className="nav-item toggle-nav" style={{ border: "none" }}>
//               <Button
//                 title="toggle Dark Mode"
//                 className="dark "
//                 onClick={theme}
//               >
//                 <Brightness4Icon
//                   className="darkthemeicon"
//                   style={{ border: "none", fontSize: "25px" }}
//                 />
//               </Button>
//             </li>
//           </ul>
//           <div
//             className={`nav-icon ${dark ? "nav-icon_dark" : "nav-icon_light"}`}
//             onClick={handleClick}
//           >
//             <i>
//               <MenuIcon style={{ fontSize: "30px", marginTop: "3px" }} />
//             </i>
//           </div>
//         </div>
//       </nav>

//       {/*========================== End of NavBar ============================*/}

//       <Switch>
//         <Suspense
//           fallback={
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 marginTop: "50px"
//               }}
//             >
//               Loading...
//             </div>
//           }
//         >
//           {/*========================== about us ============================*/}

//           <Route exact path="/about">
//             {/* <About apptheme5={dark} /> */}
//             <Faq apptheme4={dark} />
//             {/* <ContactUs apptheme={dark} /> */}
//             <Footer apptheme2={dark} />
//           </Route>
//           {/*========================== landing page ============================*/}

//           <Route exact path="/landing">
//             <Landing apptheme={dark} />
//             <Footer apptheme2={dark} />
//           </Route>
//           {/* ============================Login page ============================ */}
//           <Route exact path="/login">
//             {/* <Login apptheme={dark} /> */}
//             <Footer apptheme2={dark} />
//           </Route>
//           <Route exact path="/signup">
//             {/* <Signup apptheme={dark} /> */}
//             <Footer apptheme2={dark} />
//           </Route>
//           {/* <Route exact path="/forget"> */}
//             {/* <Forget apptheme={dark} />
//             <Footer apptheme2={dark} /> */}
//           {/* </Route> */}
//           <Route exact path="/reset">
//             <Reset apptheme={dark} />
//             <Footer apptheme2={dark} />
//           </Route>
//           {/* ============================features page ============================ */}

//           <Route exact path="/features">
//             <Features apptheme3={dark} />
//             <Footer apptheme2={dark} />
//           </Route>

//           {/*========================== home page ============================*/}

//           <Route exact path="/">
//             <div className="App">
//               {loading ? (
//                 <CircularProgress className="loading" />
//               ) : (
//                 <>
//                   <div className="scroll">
//                     <LoadingBar
//                       color=" red"
//                       progress={progress}
//                       onLoaderFinished={() => setProgress(0)}
//                     />
//                     <br />
//                     <br />
//                     <br />
//                     {/* {users.map((u) => (
//         <h1 key={u.id} user={u} />
//       ))} */}
//       <h1>{username}</h1>
//                     <button
//                       className={`${
//                         dark ? "loadOlderMessages_dark" : "loadOlderMessages"
//                       }`}
//                       onClick={loadOlderMessages}
//                     >
//                       Load Older Messages
//                     </button>
//                     <br />
//                     <br />
//                     {messages.map((message) => (
//                       <Messages
//                         messages={message.content}
//                         username={username}
//                         uid={uid}
//                         dark={dark}
//                         key={genKey()}
//                       />
//                     ))}
//                     <div />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                   </div>

//                   <div ref={messagesEndRef} />
//                   <Mention trigger="@" />
//                   <div className="div__footer">
//                     <footer className={`${dark ? "footer_dark" : ""}`}>
//                       <div className="content__footer">
//                         <div
//                           className={` ${
//                             dark ? "sendNewMessagedark" : "sendNewMessage"
//                           }`}
//                         >
//                           <button
//                             className={`addfiles ${dark ? "darkButton" : ""}`}
//                           >
//                             <i className="fa fa-plus"></i>
//                           </button>
//                           <button className="EmojiToggle">
//                             <InsertEmoticonIcon onClick={emojiToggle} />
//                           </button>
//                           {showEmojis && (
//                             <span
//                               className={`${
//                                 dark ? "EmojiPicker_dark" : "EmojiPicker"
//                               }`}
//                             >
//                               {/* <Picker onSelect={addEmoji} /> */}
//                             </span>
//                           )}
//                           <button className="KeyboardToggle">
//                             <i
//                               className="fa fa-keyboard-o"
//                               onClick={keyboardToggle}
//                             ></i>
//                           </button>
//                           {showKeyboard && (
//                             <span
//                               className={`${
//                                 dark ? "KeyboardPicker_dark" : "KeyboardPicker"
//                               }`}
//                             >
//                                <input
//                                                                 onChange={onChangeInput}
//                                                             /> 
//                               <Keyboard
//                                 keyboardRef={(r) => (keyboard.current = r)}
//                                 layoutName={layout}
//                                 onChange={onChange}
//                                 onKeyPress={onKeyPress}
//                               />
//                             </span>
//                           )}
//                           <input
//                             type="text"
//                             onChange={(e) => {
//                               setValue(e.target.value);
//                               setStatus(false);
//                             }}
//                           />
//                           <CopyToClipboard
//                             text={value}
//                             onCopy={() => setStatus(true)}
//                           >
//                             <button className="copy">
//                               {" "}
//                               <i className="fa fa-copy"></i>
//                             </button>
//                           </CopyToClipboard>
//                           {status && (
//                             <p className="copied_status"> Copied!!!</p>
//                           )}

//                           <input
//                             ref={inputElement}
//                             className={`input ${
//                               dark ? "dark_input" : "light_input"
//                             }`}
//                             type="text"
//                             placeholder="Type a message"
//                             onChange={(event) => setInput(event.target.value)}
//                             onKeyPress={handleKeypress}
//                             value={input}
//                           />
//                           <div className="speak">
//                             <button onClick={Speechtoinput}>
//                               <i className="fa fa-microphone"></i>
//                             </button>
//                             {showAlert && (
//                               <span className="Speaknow_alert">Speak now</span>
//                             )}
//                           </div>
//                               <Geolocation >  </Geolocation>
//                           <button
//                             className={`btnsend ${
//                               dark ? "darkButtonSend" : ""
//                             }`}
//                             id="sendMsgBtn"
//                             type="submit"
//                             variant="contained"
//                             crossOrigin="anonymous"
//                             onClick={newMessage}
//                           >
//                             <i className="fa fa-paper-plane"></i>
//                           </button>
//                         </div>
//                       </div>
//                     </footer>
//                     <div
//                       className={dark ? "scrolltobottomdark" : "scrolltobottom"}
//                     >
//                       <Button title="scroll to bottom" onClick={scrollToBottom}>
//                         <KeyboardArrowDownIcon
//                           className={dark ? "scrollicondark" : "scrollicon"}
//                           style={{ width: "20px", height: "40px" }}
//                         />
//                       </Button>
//                     </div>
//                     <WelcomeDialogBox
//                       className={dark ? "wlcm_dark" : "wlcm_light"}
//                       open={openWelcomeDialogBox}
//                       close={() => setOpenWelcomeDialogBox(false)}
//                       setUsername={setUsername}

//                       setUid={setUid}
//                     />
//                   </div>
//                 </>
//               )}
//             </div>
//           </Route>
//         </Suspense>
//       </Switch>
//     </Router>
//   );
// }

// // keys generator:- every new call to this function will give numbs like 0,1,2,3....
// const genKey = (function () {
//   var keyCode = 0;
//   return function incKey() {
//     return keyCode++;
//   };
// })();

// export default Chat;
