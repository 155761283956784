
// import React, { createContext, useContext, useReducer, useEffect } from "react";
// import jwtDecode from "jwt-decode";
// import Cookies from "js-cookie";

// const AuthStateContext = createContext();
// const AuthDispatchContext = createContext();

// const getToken = () => Cookies.get("token");

// const setToken = (token) => {
//   Cookies.set("token", token, { expires: 10 * 365 * 24 * 60 * 60 * 1000 });
// };

// const removeToken = () => {
//   Cookies.remove("token");
// };

// let user;
// const token = getToken();
// if (token) {
//   const decodedToken = jwtDecode(token);
//   const expiresAt = new Date(decodedToken.exp * 10 * 365 * 24 * 60 * 60 * 1000);

//   if (new Date() > expiresAt) {
//     removeToken();
//   } else {
//     user = decodedToken;
//   }
// } else {
//   console.log("No token found");
// }

// const authReducer = (state, action) => {
//   switch (action.type) {

//     case "LOGIN":
//       localStorage.setItem("token", action.payload.token);
//       setToken(action.payload.token);

//       return {
//         ...state,
//         user: action.payload,
//       };
//     case "LOGOUT":
//       removeToken();
//       localStorage.clear();
//       return {
//         ...state,
//         user: null,
//       };
//     default:
//       return state;
//   }
// };

// export const AuthProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(authReducer, { user });

//   useEffect(() => {
//     const token = getToken();
//     if (token) {
//       const decodedToken = jwtDecode(token);
//       const expiresAt = new Date(decodedToken.exp * 1000);

//       if (new Date() > expiresAt) {
//         removeToken();
//         dispatch({ type: "LOGOUT" });
//       } else {
//         dispatch({
//           type: "LOGIN",
//           payload: {
//             token,
//             ...decodedToken,
//           },
//         });
//       }
//     }
//   }, []);

//   return (
//     <AuthDispatchContext.Provider value={dispatch}>
//       <AuthStateContext.Provider value={state}>
//         {children}
//       </AuthStateContext.Provider>
//     </AuthDispatchContext.Provider>
//   );
// };

// export const useAuthState = () => useContext(AuthStateContext);
// export const useAuthDispatch = () => useContext(AuthDispatchContext);
import React, { createContext, useContext, useReducer, useEffect } from "react";
import PropTypes from 'prop-types';
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const getToken = () => Cookies.get("token");

const setToken = (token) => {
  Cookies.set("token", token, { expires: 10 * 365 * 24 * 60 * 60 * 1000 });
};

const removeToken = () => {
  Cookies.remove("token");
};

let user;
const token = getToken();
if (token) {
  const decodedToken = jwtDecode(token);
  const expiresAt = new Date(decodedToken.exp * 1000);

  if (new Date() > expiresAt) {
    removeToken();
  } else {
    user = decodedToken;
  }
} else {
  console.log("No token found");
}

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        user: null,
        isFetching: true,
        error: false,
      };
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);
      setToken(action.payload.token);

      return {
        ...state,
        user: action.payload,
        isFetching: false,
      };
    case "LOGOUT":
      removeToken();
      localStorage.clear();
      return {
        ...state,
        user: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, { user });

  useEffect(() => {
    const token = getToken();
    if (token) {
      const decodedToken = jwtDecode(token);
      const expiresAt = new Date(decodedToken.exp * 1000);

      if (new Date() > expiresAt) {
        removeToken();
        dispatch({ type: "LOGOUT" });
        window.location.href = "/"; // Redirect to home page
      } else {
        dispatch({
          type: "LOGIN",
          payload: {
            token,
            ...decodedToken,
          },
        });
      }
    } else {
      dispatch({ type: "LOGOUT" });
      // window.location.href = "/"; // Redirect to home page
    }
  }, []);

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
