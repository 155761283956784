import React, { useState, useEffect } from "react";
import styled from "styled-components";

const FullscreenButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff; /* Change to your desired background color */
  color: #fff; /* Change to your desired text color */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; /* Change to your desired hover color */
  }
`;

const elem = document.documentElement;

function useFullscreen() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const openFullscreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const toggleFullscreen = () => {
    if (isFullscreen) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);
    document.addEventListener("webkitfullscreenchange", fullscreenChangeHandler);
    document.addEventListener("msfullscreenchange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
      document.removeEventListener("webkitfullscreenchange", fullscreenChangeHandler);
      document.removeEventListener("msfullscreenchange", fullscreenChangeHandler);
    };
  }, []);

  return { isFullscreen, toggleFullscreen };
}

function FullscreenControl() {
  const { isFullscreen, toggleFullscreen } = useFullscreen();

  return (
    <div>
      <FullscreenButton onClick={toggleFullscreen}>
        {isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
      </FullscreenButton>
    </div>
  );
}

export default FullscreenControl;
