import React from "react";
import { useRef, useState } from "react";
import { useEffect } from "react";
import "../topbar/topbar.css";
import "../menuBurger/menu.css";
import MenuIcon from "@material-ui/icons/Menu";
import { useAuthDispatch, useAuthState } from "../../context/auth";
const MenuBurger = (props) => {
  // const { user, dispatch } = React.useContext(AuthContext);
  const { user } = useAuthState();
  const [open, setOpen] = useState(false);
  const dropdownwrapper = useRef(null);
  const logout = () => {
    authDispatch({ type: "LOGOUT" });
    window.location.href = "/login";
  };
  const authDispatch = useAuthDispatch();
  const handleClickOutside = (event) => {
    if (
      dropdownwrapper.current &&
      !dropdownwrapper.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // clean
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <div className="dropdownwrapper" ref={dropdownwrapper}>
      <MenuIcon onClick={() => setOpen(!open)} {...props} />
      {open && (
        <div>
          <div className="dropdpper">
            <ul className="modaly">
              <a className="menu-item" href="/">
                Home
              </a>
              <a className="menu-item" href={"/profile/" + user.id}>
                Profile de {user.username}
              </a>
              <a className="menu-item" href={"/admin/" + user.id}>
                admin
              </a>
              <a
                className="menu-item"
                // href={"/"}
                // onClick={() =>
                //   dispatch({
                //     type: "X",
                //   })
                // }
                onClick={logout}
              >
                deconexion
              </a>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
MenuBurger.displayName ="MenuBurger";
export default MenuBurger;